import Glide from '@glidejs/glide';

import onLoadAsync from './on_load_async.js';

onLoadAsync(() => {
  document.querySelectorAll('.glide').forEach((glideNode) => {
    const slidesPerView = parseFloat(glideNode.dataset.slidesPerView);
    const startAt = parseFloat(glideNode.dataset.startAt);
    const gapBetweenSlides = parseFloat(glideNode.dataset.gapBetweenSlides);
    const breakpointsSlider = glideNode.dataset.breakpointsSlider.split(',').map(Number);
    const numberOfSlides = glideNode.querySelectorAll('.glide__slide').length;
    const leftArrowClasses = glideNode.querySelector('.glide__arrow--left').classList;
    const rightArrowClasses = glideNode.querySelector('.glide__arrow--right').classList;

    if (startAt === 0) {
      leftArrowClasses.add('hidden');
    }

    const glide = new Glide(glideNode, {
      gap: gapBetweenSlides,
      bound: true,
      // we'll probably need to create a way to customize these values
      // when we'll add a slider to another bloc
      perView: slidesPerView,
      startAt,
      rewind: false,
      // approximative breakpoint - see if we want to customize directly in view
      breakpoints: {
        1000: {
          perView: breakpointsSlider[0],
        },
        767: {
          perView: breakpointsSlider[1],
        },
        650: {
          perView: breakpointsSlider[2],
        },
        420: {
          perView: 1.2,
        },
      },
    });

    glide.on('run.after', () => {
      const currentSlideIndex = glide.index;
      if (currentSlideIndex === 0) {
        leftArrowClasses.add('hidden');
      } else {
        leftArrowClasses.remove('hidden');
      }
      if (currentSlideIndex === (numberOfSlides - Math.trunc(glide.settings.perView))) {
        rightArrowClasses.add('hidden');
      } else {
        rightArrowClasses.remove('hidden');
      }
    });

    glide.mount();
  });
});
